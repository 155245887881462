@use "assets/scss/style";
@use "./form.module";

.alert {
  margin-bottom: 1rem;
}

.error {
  color: red;
}

.header {
  display: inline-flex;
  align-items: center;
  padding: 1.5rem;
  
  &.divider {
    border-top: 1px solid gray;
    line-height: 0.5rem;
  }

  &.status {
    align-items: flex-start;
  }

  .statusHeader {
    display: grid;
    grid-template-columns: 140px auto;
    align-items: baseline;
    width: 50%;
  }

  .nonDigital {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: end;
  }
  
  .applicationStatus {
    margin-bottom: 1rem;
    color: style.$primaryMain;
    font-weight: bold;
  }
}

.keyData {
  border: 1px solid style.$grey200;
  border-radius: 1rem;
  min-height: 330px;
  padding-bottom: 0;
}

.score {
  padding: 0.5rem 0 0.5rem 0;
  display: inline-flex;
  text-align:left;

  .label {
    cursor: auto;
    width: 100%;
  }
  
  & .value { 
    font-size: 1.5rem;
    font-weight: bold;
    cursor: text;
    margin-top:10px;
    text-align:left;
    width: 100%;

    &.calculated {
      color: style.$warningMain;
    }
    
    &.override {
      font-size: 2rem;
      font-weight:bold;
      text-align:left;
    }
    
    &.application {
      color: style.$primaryMain;
    }
  }
}

.largerText {
  font-size: 24px;
}

.scoreBreakdown {
  display: inline-flex;
  align-items: center;
  margin-left: 1rem;
  color: style.$warningMain;
  font-size: 1.5rem;
  vertical-align:middle;
  
  & .text {
    font-size: 0.75rem;
    color: style.$grey500;
    padding: 5px;
  }
}

.scoreRow {
  background-color: white;
  
  &.odd {
    background-color: style.$grey50;
  }
}

.scoreIncrease {
  color: style.$darkPrimaryMain;
}

.targetPopulation {
  display: list-item;
  margin-left: 3rem;
  font-style: italic;
}

.comments {
  color: black;
  width: 100%;
}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  &.end {
    flex-grow: 1;
    justify-content: end;
  }

  &.center {
    flex-grow: 1;
    justify-content: center;
  }

  &.noPad {
    padding: 0;
  }

  .left {
    display: inline;
  }

  .right {
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-end
  }

  .button {
    margin: 0.4rem;
    min-width: 100px;
    max-width: 175px;
    max-height: 40px;
    line-height: normal;
  }
}

.iconButton {
  margin-right:5px;
}

.rowTopPadding{
  padding-top:15px;
}

.alignBottom {
  display: inline-flex;
  align-items: flex-end;
}

.row {
  margin-bottom: 1rem;
}

.fullWidth {
  width: 100%;
  flex-grow: 1;
}

.halfWidth {
  width: 45%;
}

.bold {
  font-weight: bold;
}

.title {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.householdTitle {
  width: 100%;
  justify-content: left;
  margin-left: 25px;
  margin-top: 25px;
  padding-right: 25px;
  flex-grow: 1;
}

.subtitle {
  font-weight: normal;
  font-size:18px;
}

.paddedContainerTopBottom{
  margin-top:1rem;
  margin-bottom: 1rem;
}
.paddedContainer {
  margin: 1rem;
}

.applicationJourney {
  border: 1px solid style.$grey200;
  border-radius: 1rem;
  height: 440px;
  overflow-y: auto;
  padding: 1rem;
  width: unset;

  @media (width <1535px)  {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (width <= 1200px) {
    margin-right: 0;
  }
  
  @media (width >= 1535px) {
    margin-right: 1rem;
  }
  
  ul {
    margin: 0;
  }
  
  .noContent {
    height: 90%;
    width: calc(100vw * .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.formLabel {
  display: flex;
  margin: 1rem;
  align-items: center;
}

.edit {
  cursor: pointer;
  color: #808080;
  margin-left: 1rem;
}

.memberDetailContainer {
  background-color: style.$grey50;

  .memberLabel {
    @extend .formLabel
  }
}

.ineligible {
  border: 3px solid style.$warningDark !important;

  & .small {
    font-size: 12px;
    margin-left: 1rem;
  }
}

.address {
  height: unset;
}

.contactContainer {
  background-color: style.$warningLight;
  
  .contactLabel {
    @extend .formLabel;
  }
  
  .formDetail {
    background-color: unset;
  }
}

.offerDetailContainer {
  background-color: #F1F8E9;
  padding-bottom: 1rem;

  .offerLabel {
    @extend .formLabel;
  }

  .formDetail {
    line-height: 1.6rem;
    padding: 0 2rem;
    vertical-align: text-top;
  }
}

.memberDetailThreeRowsMargin {
  margin-bottom: 20px;
}

.modalContainer {
  background: white; 
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  max-height: 80vh;
  align-items: normal;
  justify-content: left;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  
  a {
    color: style.$primaryMain;
  }
  
  .documentsText {
    font-size: 0.75rem;
    color: style.$grey600;
  }
  
  .modalText {
    margin: 1rem 1rem 0;
    padding: 0.4rem;
    
    .paddedHorizontally {
      margin: 0 0.25rem;
      
      &.spaced {
        margin-bottom: 1rem;
      }
    }
  }
  
  .modalButtons {
    display: flex;
    width: 100%;
    justify-content: right;
    padding: 1rem;

    button {
      margin: 0 0.5rem;
    }

    .loader {
      margin: 0 0.5rem;
      height: 24px;
      width: 112px;
    }
  }

  .programs {
    margin: 0 auto;
  }

  .tabs {
    width: 100%;
  }

  .tab {
    margin-top: 0;
  }

  .emailTemplate {
    min-height: 390px;
  }
}

.noteRow {
  display: inline-flex;
  margin: 1rem;
  width: calc(100%);
}

.flexEnd {
  display: inline-flex;
  justify-content: flex-end;
}

.emptyGrid {
  display: flex;
  justify-content: center;
  font-style: italic;
  padding-top:30px;
}

.journeyItem {
  color: style.$primaryMain;

  &.link {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }

  & button {
    padding: 0;
  }
}

.journeyUser {
  padding-left: 0.25rem;
}

.column {
  padding: 0 1rem;
}

.documentGrid {
  display: grid;
  width: calc(100% - 4rem);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(2, 50%);
}

.documentCard {
  background: style.$grey100;

  .documentHeader {
    display: flex;
    padding: 1rem 1rem 0.5rem;
    background: white;

    .documentButton {
      justify-items: right;
    }
  }

  .documentContents {
    width: 100%;
    padding: 1rem 1rem 0.5rem;
  }

  .noDocuments {
    font-weight: bold;
    font-style: italic;
  }

  .documentContainer {
    display: grid;
    grid-template-columns: 45% 15% auto;
    align-items: center;

    .documentText {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  
    .documentActions {
      display: flex;
    }
  }
}

.subsidyHeader {
  border: 1px solid style.$grey600;
  background-color: style.$primaryLight;
}

.subsidyBody {
  border: 1px solid black;
  padding: 1rem;
}

.toggeleButton {
  padding-left: 5%;
  padding-right: 5%;
}

.emergencyContact {
  padding-bottom: 0.5rem;
}

.note {
  color: style.$primaryMain;
  font-weight: 500;
}

.emailGrid {
  min-height: 400px;
}

.vertical {
  display: flex;
  flex-direction: row;
}
@use "assets/scss/style";

.columnHeader {
  background-color: style.$paper;
  color: style.$primaryMain;
  border-bottom: 2px solid RGB(200, 200, 200);
}
.row {
  background-color: white;
  //border-top: 1px solid style.$grey200;
  height: 40px;
  &:hover {
    background-color: lighten(style.$primaryDark, 50);
  }
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.tableToolbar {
  background-color: style.$grey50;
  color: style.$primaryLight;
  margin-bottom: 30px;

  & .button {
    color: style.$primaryDark;
    padding: 15px;
  }
}

.success {
  color: green !important;
  
  & >svg {
    color: green !important;
  }
}

.failure {
  color: red !important;
  
  & >svg {
    color: red !important;
  }
}

.actions {
  padding: 0 1rem;
}

.buttonHeaderGroup {
  display: flex;
  justify-content: flex-end;
  background-color: style.$grey50;
}

.emptyGrid {
  display: flex;
  justify-content: center;
  font-style: italic;
  padding-top: 30px;
}

.table {
}

.compactButton {
  padding: 0;
}

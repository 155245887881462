@use "assets/scss/style";

.enhancedBackground {
  padding: 0.5rem;
  background-color: style.$grey100;
  width: 100%;
}

.roundedCorners {
  border: 1px solid style.$grey100;
  border-radius: 0.25rem;
}

.emailHeader {
  display: flex;
  font-size: 10px;
  align-items: center;

  .end {
    display: inline-flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  }
}

.fullWidth {
  width: 100%;
  flex-grow: 1;
}

.omitted {
  opacity: 0.3;
}

.buttons {
  align-items: center;
}
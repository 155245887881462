@use "assets/scss/style";

.container {
  display: inline-flex;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  width: 350px;
  padding: 0.3rem;
  align-items: baseline;
}

.button {
  width: 100px;
  height: 40px;
}

.period {
  display: inline-flex;
  align-items: center;
  border: 1px solid style.$grey300;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: bold;

  & legend {
    padding: 0 10px
  }
}
@use "assets/scss/style";

.container {
  border: 1px solid style.$primaryDark;
  border-radius: 0.5rem;
  background-color: style.$primaryLight;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}

.logo {
  display: inline-flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.value {
  font-weight: bold;
}
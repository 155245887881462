@use "assets/scss/style";
@use "./form.module";

.rateCalculation {
  padding: 1rem 0;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;

  &.compressed {
    justify-content: start;
  }
}

.rateRow {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  grid-gap: 1rem;
  margin: 2rem 0;
}

.groupingRow {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 1rem;
  padding: 1rem 1rem 0 0;
}

.fixedIncomeRate {
  margin-left: 0!important;
  width: 50%;
  padding-bottom: 1rem;

  & .fixedIncomeRateRow {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    gap: 1rem;
    margin: 1rem 0;
  }
}

.marketRateToggle {
  display: inline-flex;
  font-weight: normal;
  font-size: 12px;
  margin-left: calc(100% - 100px);
  align-items: center;
}
.dropDown {
  width: 200px;
  margin: 0 1rem;
}

.bold {
  font-weight: bold;
}

.selectList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  
  .button {
    margin-right: 1rem;
  }
}
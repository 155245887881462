@use "assets/scss/style";

.header {
  font-weight: bold;
}

.programGrid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 1rem;
  width: 100%;
  padding: 0 1.4rem;
  padding-top: 1rem;

  .selectedProgram {
    padding-top: 1rem;
  }
}

.clientContainer {
  width: 100%;
  padding: 0 1rem;

  .clientGrid {
    margin: 0.5rem 0;
    border: 1px solid style.$grey500;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: repeat(6, auto);

    .row {
      line-height: 2rem;
      padding: 0 0.25rem;
    }

    .odd {
      background-color: style.$grey200;
    }

    .empty {
      grid-column: 1 / span 6;
      display: flex;
      justify-content: center;
      font-style: italic;
      padding: 0.5rem;
    }
  }
}

.keepTogether {
  display: block;
  word-wrap: break-word;
  white-space: normal;
}

.note {
  padding: 0.5rem 0;
  background-color: #0070c4;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  color: antiquewhite;

  .info {
    padding-left: 0.25rem;
  }
}

.disbursementAmounts {
  margin: 1rem 0;
  
  .label {
    font-weight: bold;
    color: unset;
  }
  
  .value {
    margin-right: 3rem;
  }
  
  .required {
    color: red;
  }
}
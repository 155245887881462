@use "assets/scss/style";
@use "./application.module";

.clientBanner {
  background: style.$secondaryLight;
  background: radial-gradient(circle, white 0%, style.$secondaryLight 75%, style.$secondaryLight 100%);
  
  .bannerAvatar {
    height: 100px;
    width: 100px;
    background-color: style.$primaryMain;
    color: style.$secondaryLight;
  }

    .recipientStatus {
      display: grid;
      grid-template-columns: 200px auto 250px;
      align-items: center;
  
      .nonDigital {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: end;
      }
    }
}

.clientTitle {
  display: flex;
  align-items: center;

  .clientTitleText {
    width: 50%;
  }
}

.tabs {
  background-color: white;
}

.contentBg {
  background-color: white;
  padding: 25px 25px 25px 25px;
  width:100%;

  & .title {
    display: grid;
    grid-template-columns: auto 230px;
    padding-right: 1.5rem;
  }
}

.topPadding {
  padding-top:25px;
}

.status {
  font-weight: bold;
}

.Active {
  @extend .status;
  color: style.$primaryMain;
}

.Inactive {
  @extend .status;
  color: style.$errorDark;
}

.Suspended {
  @extend .status;
  color: style.$warningDark;
}

.noPadding {
  padding: 0 !important;
}

.clientEmail {
  color: style.$primaryMain;
}

.spaceRight {
  margin-right: 0.5rem;
}

.tabTitle {
  width: 100%;
  justify-content: left;
  margin-left: 25px;
  margin-top: 25px;
  padding-right: 25px;
  flex-grow: 1;
}

.paymentContainer {
  padding: 1.5rem;
}

.buttonGroup {
  display: inline-flex;
  padding: 1rem 3rem 1rem;
  justify-content: flex-end;

  .button {
    width: 150px;
  }
}

.statusButtons {
  display: inline-flex;
  justify-content: flex-end;

  & button {
    margin-left: 1rem;
    width: 150px;
  }
}

.select {
  width: 300px;
}

.left {
  align-self: start;
}

.leftPadding {
  padding-left: 0.5rem;
}

.onNotice {
  margin: 0.5rem 1rem 0;
  padding: 0.4rem;
  width: 70%;
}

.priorityNote {
  margin-left: 5rem;
  color: style.$warningDark;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.accountStatus {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  height: 14px;
}

.programForm {
  padding: 1.5rem;

  .programRow {
    display: grid;
    margin: 1rem;
    grid-template-columns: repeat(3, 170px);
    gap: 3rem;
  }
}

.pdfDownload {
  padding-right: 1rem;
}
@use "assets/scss/style";

.applicantDetails {
    border: 1px solid style.$primaryDark;
    border-radius: 0.25rem;
    background-color: style.$primaryMain;
    padding: 1rem;
    margin: 1rem;
    width: 100%;

    .values {
        display: grid;
        grid-template-columns: repeat(12, auto);
        gap: 0.25rem;

        .value {
            color: style.$paper;
            font-weight: 600;
        }
    }
}

.duplicates {
    padding: 1rem;

    .sectionTitle {
        color: style.$grey600;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }

    .duplicateGrid {
        border: 1px solid style.$grey500;
        border-radius: 0.25rem;
        display: grid;
        grid-template-columns: 120px 200px 120px 250px repeat(2, 100px) auto;
        padding: 0.5rem 1rem;
        min-height: 200px;

        .header {
            font-weight: bold;
        }

        .row {
            line-height: 2rem;
        }

        .even {
            background-color: style.$grey200;
        }

        .empty {
            grid-column: 1 / span 7;
            display: flex;
            justify-content: center;
            padding-bottom: 1rem;
        }
    }
}
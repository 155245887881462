@use "assets/scss/style";

.container {
  width: 100%;
  box-sizing: border-box;
}

.fieldset {
  border: 1px solid style.$grey300;
  border-radius: 8px;
  padding: 0 10px;
  background: style.$grey50;

  &.error:not(.active) {
    border: 1px solid red;
  }

  &.error.active {
    border: 2px solid red;
  }

  &.active {
    border: 2px solid style.$primaryMain;
    box-sizing: border-box;
  }
}

.textarea {
  border: 0;
  box-sizing: content-box;
  width: 100% !important;
  background: style.$grey50;
  padding-left: 8px;
  padding-right: 0;
  flex-grow: 1;
}

span.active {
  color: style.$primaryMain;
}

textarea:focus,
textarea:active {
  outline: none;
}
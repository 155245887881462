@use "assets/scss/style";
@use "./form.module";

a.ppulusHelp {
    text-decoration: none;
}

.progressValue {
    color: style.$primaryMain;
}

.versionDisplay {
    width: 250px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

.overflowText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@use "src/assets/scss/style";

.stretchHeightSmall{
  height: 100px;
}

.stretchHeightMedium{
  height: 300px;
}

.stretchHeightLarge{
  height: 500px;
}

.card {
  line-height: 1.5rem;
  padding: 1rem;
  margin: 1rem;
  
  &:not(.unbordered) {
    border: 1px solid style.$grey300;
    border-radius: 1rem;
  }
  @media (width <= 1535px) {
    margin: 1rem 0 1rem 0;
  }
}

.formDetailContainer {
  margin: 1rem;
  background-color: style.$grey50;
}

.formDetail {
  line-height: 1.6rem;
  padding: 1rem 1rem 1rem 2rem;
  vertical-align:text-top;
}

.chips {
  line-height: 2.5rem;
  
  div {
    margin-right: 1rem;
  }
}

.recordDetailCard {
  line-height: 1.5rem;
  padding: 1rem;
  margin: 1rem;
  background-color: style.$primaryLight;

  &:not(.unbordered) {
    border: 1px solid style.$primaryMain;
    border-radius: 1rem;
  }
}

.content {
  border-radius: 0.5rem;
  background-color: white;
  padding: 2rem;
  width: 100%;
  vertical-align: top;
}

.formRow {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  grid-gap: 1rem;
  margin: 2rem 0;
}

.extendedRow {
  width: 75%;
  margin: 2rem 0;
}

.buttonGroup {
  width: 75%;
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  .button {
    width: 200px;
  }

  .button:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.grouping {
  background-color: style.$grey100;
  border: 1px solid style.$grey600;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.label {

}

.keyValue {
  font-weight: bold;
  font-size: 1.5rem;

  &.primary {
    color: style.$primaryMain;
  }
}

.noPadding {
  margin: 0;
  padding: 0;
}

.topBottomMargin {
  margin: 1rem;
}

.error {
  border: red;
}
@use "assets/scss/style";

.changesContainer {
  margin-bottom: 50px;
}

.subText {
  position: relative;
  top: -0.5rem;
  margin-bottom: 1.5rem;
}

.list {
  margin-bottom: 2rem;
}

.property {
  font-weight: bold;
  padding-right: 0.5rem;
}

.oldValue {
  text-decoration-line: line-through;
  text-decoration-style: dashed;
  padding-right: 0.25rem;
  color: style.$grey500;
}

.newValue {
  font-style: italic;
  padding-left: 0.25rem;
  color: style.$darkPrimaryMain;
}
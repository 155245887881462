@use "assets/scss/style";

.totals {
  display: inline-flex;
  flex-flow: row;
  border-bottom: 1px solid style.$grey300;
  width: 100%;
}

.totalContainer {
  display: flex;
  flex-flow: column;
  background: style.$primaryMain;
  border: 1px solid white;
  border-radius: 0.5rem;
  justify-items: center;
  margin: 1rem;
  width: 250px;

  & .value {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: white;
    padding: 2rem 0 1rem;
  }

  & .label {
    color: white;
    text-align: center;
    padding: 0 0 1rem;
  }
}
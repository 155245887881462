@use "assets/scss/style";

.enhancedBackground {
  padding: 0.5rem;
  background-color: style.$grey100;
  width: 100%;
}

.roundedCorners {
  border: 1px solid style.$grey100;
  border-radius: 0.25rem;
}

.header {
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.loader {
  min-height: 510px;
}

.downloadButtons {
  text-align: right;
  margin-top: 1rem;
}
@use "assets/scss/style";
@use "./form.module";

.header {
  padding: 1.5rem;

    & .chip {
    margin-left: 2rem;
    color: white;
    
    &.success {
      background-color: #4caf50;
    }
    &.failure {
      background-color: #ed6c02;
    }
  }
}

.formValue {
  display: inline-flex;
  align-items: center;
}

.label {
  width: calc(50%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1rem;
}

.subtitle {
  font-weight: normal;
}

.screening {
  @extend .card;
  background-color: style.$grey50;
}

.admin {
  @extend .recordDetailCard;
  background-color: style.$primaryLight;
}

.screeningDataField {
  display: inline-flex;
  align-items: center;
  padding: 1rem;
  margin: 0;
  height: 25px;
}
@use "src/assets/scss/style";

.dropzoneContainer {
  width: 100%;
  height: 100px;
  border: 1px dashed style.$primaryMain;
  border-radius: 1rem;
  background-color: style.$grey100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.accepted {
  cursor: grabbing;
  border-width: 2px;
  border-color: style.$primaryDark;
}

@use "assets/scss/style";

.content {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.button {
  width: 200px;
  height: 48px;;
  align-items: center;

  svg {
    fill: white;
  }
}

.optionsGrid {
  display: grid;
  grid-template-columns: 30% auto max-content;
  grid-gap: 2rem;
  align-items: baseline;
}

.section {
  border: 1px solid style.$grey300;
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
}

.paymentHeader {
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 0.5rem;
  font-weight: bold;

  .right {
    display: inline-flex;
    flex-grow: 1;
    align-self: center;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }
}
@use "./form.module";

.grouping {
  width: 75%;
  margin: 1rem 0;

  .row {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-gap: 1rem;
    margin: 2rem 0;
  }

  .featureFlag {
    display: grid;
    grid-template-columns: repeat(2, 80%);
    grid-gap: 1rem;
    margin: 2rem 0;
    align-items: center;
    border-bottom: 1px solid grey;
  }
}


.span2 {
  grid-column: 1 / span 2;
}